type Props = {
  label: string
  onChangeHandler: (event: React.ChangeEvent<HTMLSelectElement>) => void
  children: React.ReactNode
  defaultValue?: number
}

const Select: React.FC<Props> = ({
  label,
  onChangeHandler,
  children,
  defaultValue,
}) => {
  return (
    <section className="flex w-full flex-col px-[8px] py-[16px]">
      <label className="font-bold">{label}</label>
      <select
        className="h-[40px] w-full rounded-[10px] border"
        value={defaultValue}
        onChange={onChangeHandler}
      >
        <option value={undefined}>未選択</option>
        {children}
      </select>
    </section>
  )
}

export default Select
