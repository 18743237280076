import { apiClient } from 'apis'

export function useCollab(): [
  talkWithAI: (
    streamer_id: number,
    assistant_id: number,
    transcript: string,
    stream_id: string
  ) => void
] {
  const talkWithAI = async (
    streamer_id: number,
    assistant_id: number,
    transcript: string,
    stream_id: string
  ) => {
    try {
      await apiClient.post(
        '/talks/voices/collab',
        {
          streamer_id: streamer_id,
          assistant_id: assistant_id,
          streamer_speech: transcript,
          stream_id: stream_id,
        },
        {
          headers: {
            Authorization: `Token ${sessionStorage.getItem('token')}`,
          },
        }
      )
    } catch (e) {
      console.error(e)
    }
  }

  return [talkWithAI]
}
