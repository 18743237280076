import axios from 'axios'

export type ApiConfig = {
  baseURL: string
  timeout: number
}

const config: ApiConfig = {
  baseURL: process.env.REACT_APP_BASE_URL!,
  timeout: 900000,
}

export const apiClient = axios.create(config)
