type Props = {
  label: string
  type: string
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
  defaultValue?: string
}

const Input: React.FC<Props> = ({
  label,
  type,
  onChangeHandler,
  defaultValue,
}) => {
  return (
    <section className="flex w-full flex-col px-[8px] py-[16px]">
      <label className="font-bold">{label}</label>
      <input
        className="h-[40px] w-full rounded-[10px] border"
        type={type}
        value={defaultValue}
        onChange={onChangeHandler}
      />
    </section>
  )
}

export default Input
