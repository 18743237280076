import moment from 'moment'
import { useEffect, useState } from 'react'
import { Dna } from 'react-loader-spinner'
import { AiOutlineStop } from 'react-icons/ai'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'
import { useAssistants, Assistant } from 'hooks/use_assistants'
import { useAuth } from 'hooks/use_auth'
import { useCollab } from 'hooks/use_collab'
import { useStreamers, Streamer } from 'hooks/use_streamers'
import Select from 'components/select'
import Input from 'components/input'
import Button from 'components/button'
import Dialogue from 'components/dialogue'

const SpeechRecognizer: React.FC = () => {
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition()

  const [assistants] = useAssistants()
  const [fetchAuth, _] = useAuth({})
  const [streamers] = useStreamers()
  const [talkWithAI] = useCollab()

  const [assistantId, setAssistantId] = useState<number>()
  const [lastTimePeriod, setLastTimePeriod] = useState<moment.Moment>()
  const [openDialogue, setOpenDialogue] = useState<boolean>(false)
  const [previousTranscript, setPreviousTranscript] = useState<string>()
  const [requested, setRequested] = useState<boolean>(false)
  const [streamId, setStreamId] = useState<string>()
  const [streamerId, setStreamerId] = useState<number>()

  const streamerIdHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStreamerId(Number(event.target.value))
  }

  const assistantIdHandler = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setAssistantId(Number(event.target.value))
  }

  const streamIdHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStreamId(event.target.value)
  }

  const sleep = () => {
    return new Promise(resolve => {
      setTimeout(() => resolve(null), 2000)
    })
  }

  const createPeriodicalRequest = async () => {
    await sleep()
    console.log('periodical calling...')

    if (requested) return

    if (transcript && transcript.length > 0) {
      if (transcript === previousTranscript) {
        console.log('started request')

        console.log(transcript)
        console.log(previousTranscript)

        if (streamerId && assistantId && transcript && streamId) {
          setRequested(true)
          await talkWithAI(streamerId, assistantId, transcript, streamId)
          setRequested(false)

          resetTranscript()

          console.log('finished request')
        }
      }
      setPreviousTranscript(transcript)
    }

    setLastTimePeriod(moment())
  }

  const setUpStreamInfo = () => {
    const storedStreamId = sessionStorage.getItem('stream_id')
    const storedStreamerId = sessionStorage.getItem('streamer_id')
    const storedAssistantId = sessionStorage.getItem('assistant_id')
    if (storedStreamId) setStreamId(storedStreamId)
    if (storedStreamerId) setStreamerId(Number(storedStreamerId))
    if (storedAssistantId) setAssistantId(Number(storedAssistantId))

    if (
      !storedStreamId &&
      !storedStreamerId &&
      !storedAssistantId &&
      !streamId &&
      !streamerId &&
      !assistantId
    ) {
      setOpenDialogue(true)
    }
  }

  useEffect(() => {
    fetchAuth(true, '/')
    setUpStreamInfo()
    if (listening) createPeriodicalRequest()
  }, [listening, lastTimePeriod])

  return (
    <main className="flex h-screen w-screen justify-center">
      {!browserSupportsSpeechRecognition && (
        <Dialogue
          label="音声取得を開始できません"
          text="ブラウザが音声認識に対応していません"
          buttonLabel="確認"
          dismissHandler={() => setOpenDialogue(false)}
        />
      )}
      {(!streamerId || !assistantId || !streamId) && openDialogue && (
        <Dialogue
          label="音声取得を開始できません"
          text="Stream ID、配信者、AI 配信者のすべてもしくはいずれかが入力されていません。"
          buttonLabel="確認"
          dismissHandler={() => setOpenDialogue(false)}
        />
      )}
      {streamerId && assistantId && streamId && openDialogue && (
        <Dialogue
          label="配信情報を保存しました"
          text="これらの配信情報はこのブラウザのウィンドウを閉じるまで有効になります。閉じた場合は再度入力をお願いします。"
          buttonLabel="確認"
          dismissHandler={() => setOpenDialogue(false)}
        />
      )}
      <article className="flex min-w-[320px] flex-col">
        {listening ? (
          <section className="flex w-full flex-col items-center px-[8px] py-[24px]">
            <Dna
              visible={true}
              height="200"
              width="200"
              ariaLabel="dna-loading"
              wrapperStyle={{}}
              wrapperClass="dna-wrapper"
            />
            <div className="py-[16px] text-center">
              <p className="font-bold">音声取得中</p>
              {requested ? <p>リクエスト待機中...</p> : <p>{transcript}</p>}
            </div>
          </section>
        ) : (
          <section className="flex w-full flex-col items-center p-[24px]">
            <AiOutlineStop color={'#BDBDBD'} size={200} />
            <div className="py-[16px]">
              <p className="font-bold">音声取得停止中</p>
            </div>
          </section>
        )}
        <section>
          <Button
            label="聞き取り開始"
            onClickHandler={() => {
              if (!streamerId || !assistantId || !streamId) {
                setOpenDialogue(true)
              } else {
                SpeechRecognition.startListening({
                  language: 'ja',
                  continuous: true,
                })
              }
            }}
          />
          <Button
            label="聞き取り停止"
            onClickHandler={() => {
              SpeechRecognition.stopListening()
              resetTranscript()
            }}
            disabled={!streamerId || !assistantId || !streamId || !listening}
          />
        </section>
        <section>
          <Input
            label="Stream ID"
            type="text"
            defaultValue={streamId}
            onChangeHandler={streamIdHandler}
          />
          <Select
            label="配信者"
            defaultValue={streamerId}
            onChangeHandler={streamerIdHandler}
          >
            {streamers.map((streamer: Streamer) => (
              <option key={streamer.id} value={streamer.id}>
                {streamer.name}
              </option>
            ))}
          </Select>
          <Select
            label="AI 配信者"
            defaultValue={assistantId}
            onChangeHandler={assistantIdHandler}
          >
            {assistants.map((assistant: Assistant) => (
              <option key={assistant.id} value={assistant.id}>
                {assistant.name}
              </option>
            ))}
          </Select>
          <Button
            label="保存"
            onClickHandler={() => {
              if (streamId) sessionStorage.setItem('stream_id', streamId)
              if (streamerId)
                sessionStorage.setItem('streamer_id', String(streamerId))
              if (assistantId)
                sessionStorage.setItem('assistant_id', String(assistantId))
              setOpenDialogue(true)
            }}
          />
        </section>
      </article>
    </main>
  )
}

export default SpeechRecognizer
