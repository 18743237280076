import SignIn from 'components/sign_in'
import SpeechRecognizer from 'components/speech_recognizer'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

const router = createBrowserRouter([
  {
    path: '/',
    element: <SignIn />,
  },
  {
    path: '/speech_recognizer',
    element: <SpeechRecognizer />,
  },
])

function App() {
  return <RouterProvider router={router} />
}

export default App
