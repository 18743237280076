import { useAuth } from 'hooks/use_auth'
import { useEffect, useState } from 'react'
import Button from 'components/button'
import Input from 'components/input'

const SignIn: React.FC = () => {
  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [fetchAuth, signIn] = useAuth({ username, password })

  const usernameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value)
  }

  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  useEffect(() => {
    fetchAuth(false, '/speech_recognizer')
  }, [])

  return (
    <main className="flex h-screen w-screen justify-center">
      <article className="flex min-w-[320px] flex-col">
        <Input
          label="ユーザー名"
          type="text"
          onChangeHandler={usernameHandler}
        />
        <Input
          label="パスワード"
          type="password"
          onChangeHandler={passwordHandler}
        />
        <Button
          label="ログイン"
          onClickHandler={() => fetchAuth(false, '/speech_recognizer')}
        />
      </article>
    </main>
  )
}

export default SignIn
