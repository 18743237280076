import { useEffect, useState } from 'react'
import { apiClient } from 'apis'

export type Assistant = {
  id: number
  name: string
  created_at: string
  updated_at: string
}

export function useAssistants(): [assistants: Assistant[]] {
  const [assistants, setAssistants] = useState<Assistant[]>([])

  const fetchAssistants = async () => {
    try {
      const res = await apiClient.get('/roles/list?role_type=assistant', {
        headers: {
          Authorization: `Token ${sessionStorage.getItem('token')}`,
        },
      })

      if (!res || !res.data) return

      setAssistants(res.data.assistants)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchAssistants()
  }, [])

  return [assistants]
}
