import { useEffect, useState } from 'react'
import { apiClient } from 'apis'

export type Streamer = {
  id: number
  name: string
  created_at: string
  updated_at: string
}

export function useStreamers(): [streamers: Streamer[]] {
  const [streamers, setStreamers] = useState<Streamer[]>([])

  const fetchStreamers = async () => {
    try {
      const res = await apiClient.get('/roles/list?role_type=streamer', {
        headers: {
          Authorization: `Token ${sessionStorage.getItem('token')}`,
        },
      })

      if (!res || !res.data) return

      setStreamers(res.data.streamers)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    fetchStreamers()
  }, [])

  return [streamers]
}
