type Props = {
  label: string
  onClickHandler: (event: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
}

const Button: React.FC<Props> = props => {
  return (
    <section className="flex w-full flex-col px-[8px] py-[16px]">
      <button
        className={
          props.disabled
            ? 'h-[40px] w-full cursor-pointer rounded-[10px] border bg-[#BDBDBD] text-white'
            : 'h-[40px] w-full cursor-pointer rounded-[10px] border'
        }
        onClick={props.onClickHandler}
        disabled={props.disabled || false}
      >
        {props.label}
      </button>
    </section>
  )
}

export default Button
