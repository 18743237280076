import { apiClient } from 'apis'
import { useNavigate } from 'react-router-dom'

export function useAuth(input: {
  username?: string
  password?: string
}): [
  fetchAuth: (authRequired: boolean, navigatePath: string) => void,
  signIn: () => Promise<string>
] {
  const navigate = useNavigate()

  const fetchAuth = async (authRequired: boolean, navigatePath: string) => {
    const token = await signIn()
    if (authRequired && !token) {
      navigate(navigatePath)
    } else if (!authRequired && token) {
      navigate(navigatePath)
    }
  }

  const signIn = async () => {
    const existingToken = sessionStorage.getItem('token')

    if (existingToken) return existingToken

    if (!input.username || !input.password) return

    let res
    try {
      res = await apiClient.post('/tokens/issue', {
        username: input.username,
        password: input.password,
      })
    } catch (e) {
      console.error(e)
    }

    if (!res || !res.data) return

    const token = res.data.token

    if (!token) return

    sessionStorage.setItem('token', token)

    return token
  }

  return [fetchAuth, signIn]
}
