type Props = {
  label: string
  text: string
  buttonLabel: string
  dismissHandler: () => void
}

const Dialogue: React.FC<Props> = props => {
  return (
    <article className="absolute h-screen w-screen bg-black/30">
      <section className="absolute left-[50%] top-[50%] flex min-h-[180px] w-[300px] translate-x-[-50%] translate-y-[-50%] flex-col justify-between rounded-[10px] bg-white p-[16px]">
        <dt className="py-[12px]">
          <p className="text-lg font-bold">{props.label}</p>
        </dt>
        <dd className="py-[12px]">
          <p>{props.text}</p>
        </dd>
        <dd className="py-[12px]">
          <button
            className="h-[40px] w-full rounded-[10px] bg-[#59DDB8] font-bold text-white"
            onClick={props.dismissHandler}
          >
            {props.buttonLabel}
          </button>
        </dd>
      </section>
    </article>
  )
}

export default Dialogue
